@import "styles/vars";

main.v-home {
	> section {
		padding-left: 32px;
		padding-right: 32px;
		position: relative;
		transform: translateZ(0);
		@include max($mMobileL) {
			padding-left: 16px;
			padding-right: 16px; } }

	h2 {
		font-size: 56px;
		line-height: 120%;
		margin-bottom: 16px; } }
