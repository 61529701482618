@import "styles/vars";

header.c-header {
	@include pos(fixed, 0, 0, 0);
	z-index: 1200;
	padding: 32px;
	background: rgba($colorDark, .7);
	-webkit-backdrop-filter: blur(24px);
	backdrop-filter: blur(24px);
	overflow: hidden;
	transform: translateZ(0);
	@include max($mTabletB) {
		padding: 25px 16px;
		max-height: 81px;
		@include transition;
		&.expanded {
			max-height: 90vh;
			max-height: 90svh;
			background: #14161D;
			div.h-burger {
				span {
					opacity: 0; }
				&:before {
					transform: translateY(5px) rotate(45deg) translateZ(0); }
				&:after {
					transform: translateY(-5px) rotate(-45deg) translateZ(0); } } } }
	@include max($mMobileL) {
		max-height: 72px; }

	div.h-burger {
		@include pos(absolute, 28px, auto, 16px);
		width: 24px;
		height: 24px;
		display: none;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 3px;
		cursor: pointer;
		@include max($mTabletB) {
			display: flex; }
		@include max($mMobileL) {
			top: 24px; }
		&:before, &:after {
			content: ""; }
		span, &:before, &:after {
			width: 100%;
			height: 2px;
			border-radius: 1px;
			background: white;
			@include transition; }
		span {
			margin: 3px 0; } }

	div.h-inner-wrapper {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: $sizeMaxWidth;
		@include max($mTabletB) {
			height: 90vh;
			height: 90svh;
			display: block; }

		img.h-logo {
			display: block;
			@include max($mTabletB) {
				margin-bottom: 25px; }
			@include max($mMobileL) {
				height: 22px; } }

		.h-router {
			display: flex;
			align-items: center;
			justify-content: center;
			@include childSpaceRight(32px);
			@include max($mTabletB) {
				padding: 25px;
				flex-direction: column;
				@include childSpaceRight(0);
				@include childSpaceBottom(12px); }

			.h-route {
				font-weight: 200;
				text-transform: capitalize;
				@include max($mTabletB) {
					font-size: 19px;
					font-weight: 600; } } }

		.h-actions {
			@include max($mTabletB) {
				display: flex;
				align-items: center;
				justify-content: center; } } } }
