$fontMain: "Kanit", "Emoji", sans-serif;
$fontHead: "Fjalla One", "Emoji", sans-serif;

$transition: cubic-bezier(.23,1,.32,1);
$tjelly: cubic-bezier(.55,.17,.39,1.21);

$transitionTime: 450ms;

$colorMain: #00CBFF;
$colorAccent: #7B2CFB;
$colorDark: #131519;
$colorHighlight: #308EFE;

$colorPositive: #90FF21;
$colorWarning: #FFC107;
$colorDanger: #FF8D07;
$colorNegative: #F44336;

$gradientMain: linear-gradient(to right, $colorMain, $colorAccent);
$gradientReversed: linear-gradient(to left, $colorMain, $colorAccent);
$gradientBackground: linear-gradient(to bottom, rgba(white, .08), rgba(white, .04));

$sizeMaxWidth: 1240px;

$mDesktopS: 1300px;
$mTabletA: 1100px;
$mTabletB: 800px;
$mMobileL: 600px;
$mMobileM: 480px;
$mMobileS: 320px;

@mixin singleRunAnimation($name, $duration: $transitionTime, $timingFunction: $transition) {
	animation-name: $name;
	animation-duration: $duration;
	animation-timing-function: $timingFunction;
	animation-fill-mode: both;
	animation-iteration-count: 1; }

@mixin toe {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden; }

@mixin pos($position, $top: auto, $left: auto, $right: auto, $bottom: auto) {
	position: $position;
	top: $top;
	left: $left;
	right: $right;
	bottom: $bottom; }

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content; }
	&::-webkit-placeholder {
		@content; }
	&::-ms-input-placeholder {
		@content; }
	&::-moz-placeholder {
		@content; }
	&::placeholder {
		@content; } }

@mixin scrollbar($color: $colorAccent, $at: .38, $bt: .72) {
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px; }
	&::-webkit-scrollbar-track {
		background: rgba($color, $at); }
	&::-webkit-scrollbar-thumb {
		background: rgba($color, $bt);
		&:hover {
			background: rgba($color, 1); } } }

@mixin transition($transitionProperties...) {
	@if length($transitionProperties) == 0 {
		transition-property: all; }
	@else {
		transition-property: $transitionProperties; }

	transition-duration: $transitionTime;
	transition-timing-function: $transition; }

@mixin max($size) {
	@media (max-width: $size) {
		@content; } }

@mixin childSpaceTop($margin) {
	> * {
		margin-top: $margin;
		&:last-child {
			margin-top: 0; } } }

@mixin childSpaceLeft($margin) {
	> * {
		margin-left: $margin;
		&:last-child {
			margin-left: 0; } } }

@mixin childSpaceRight($margin) {
	> * {
		margin-right: $margin;
		&:last-child {
			margin-right: 0; } } }

@mixin childSpaceBottom($margin) {
	> * {
		margin-bottom: $margin;
		&:last-child {
			margin-bottom: 0; } } }
