@import "styles/vars";

div.c-parallax-layer {
	@include pos(fixed, 0, 0, 0);
	height: 100vh;
	height: 100lvh;
	pointer-events: none;
	overflow: hidden;
	transform: translateZ(0);
	div.pl-content {
		width: 100%;
		overflow: visible;
		position: relative;
		will-change: transform;
		@include transition(transform); } }
