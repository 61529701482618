@import "styles/vars";

section.c-we-made-this {
	padding-top: 155px;
	padding-bottom: 135px;
	@include max($mMobileL) {
		padding-left: 0 !important;
		padding-right: 0 !important;
		position: relative;
		padding-top: 100px; }

	div.__debug_center {
		@include pos(absolute, 0, 50%, auto, 0);
		width: 1px;
		background: red;
		z-index: 99999;
		pointer-events: none; }

	div.hwmt-content {
		max-width: $sizeMaxWidth;
		margin: 0 auto;
		position: relative;

		div.helpers {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 16px;
			img {
				width: 49px;
 }				// transform: translateY(-0.5px)
			.st {
				flex-grow: 1;
				flex-shrink: 1; } }

		.st {
			span {
				display: block;
				font-family: $fontHead;
				font-size: 32px;
				text-align: center;
				@include max($mMobileL) {
					font-size: 19px; }
				span {
					color: white;
					display: inline;
					font-size: smaller;
					vertical-align: top; } } }

		h2 {
			@include pos(absolute, 0, 0);
			font-size: 72px;
			@include max($mTabletB) {
				font-size: 48px;
				position: static;
				text-align: center;
				margin-bottom: 32px; }
			@include max($mMobileL) {
				font-size: 30px; } }

		div.hwmt-grid {
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
			flex-wrap: wrap;
			margin-right: -30px;
			margin-bottom: -30px;
			@include max($mMobileL) {
				margin-right: 0;
				justify-content: center; }
			> * {
				width: calc(25% - 30px);
				margin-right: 30px;
				margin-bottom: 30px;
				@include max($mTabletB) {
					width: calc(50% - 30px); }
				@include max($mMobileL) {
					width: 100%;
					max-width: 320px;
					margin-right: 0; } }

			.hwmt-empty {
				display: flex;
				align-items: center;
				justify-content: center;
				&:not(.st) {
					@include max($mTabletB) {
						display: none; } }
				@include max($mMobileL) {
					display: none; } } } } }
