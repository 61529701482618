@import "styles/vars";

div.c-autoscroller {
	overflow-x: hidden;
	overflow-y: hidden;
	-webkit-user-select: none;
	user-select: none;
	-webkit-touch-callout: none !important;
	transform: translateZ(0);
	will-change: scroll-position;
	&.__scrollable {
		overflow-x: scroll;
 }		// background: red

	div.as-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		box-sizing: content-box;
		height: 460px;

		div.as-item {
			width: 100%;
			flex-grow: 1;
			flex-shrink: 1;
			min-width: 0;
			pointer-events: none;
			// debug
 } } }			// box-shadow: inset 0 0 0 1px lime
