@import "styles/vars";

section.c-home-overview-mobile {
	padding-top: 120px;

	div.hosm-inner-wrapper {
		div.hosm-list {
			position: relative;
			padding-left: 32px;

			span.progress {
				@include pos(absolute, 20px, -1px);
				width: 2px;
				background: $colorPositive;
				@include transition;
				@include max($mMobileL) {
					top: 15px; } }

			div.hosm-list-item {
				position: relative;
				margin-bottom: 36px;
				&:before {
					content: "";
					@include pos(absolute, 0, -33px, auto, -36px);
					width: 2px;
					background: rgba(white, .12); }

				&:first-of-type {
					&:before {
						top: 20px;
						@include max($mMobileL) {
							top: 15px; } } }

				&:last-of-type {
					margin-bottom: 0;
					&:before {
						bottom: auto;
						height: 20px;
						@include max($mMobileL) {
							height: 15px; } } }

				&.visually-expanded {
					header {
						span.dot {
							img {
								transform: none; } } } }

				header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 40px;
					cursor: pointer;
					@include max($mMobileL) {
						height: 30px; }
					&:hover {
						div.toggler {
							background: rgba($colorMain, .24); } }

					span.dot {
						@include pos(absolute, 18px, -34px);
						display: flex;
						align-items: center;
						justify-content: center;
						width: 4px;
						height: 4px;
						background: #D9D9D9;
						border-radius: 50%;
						@include max($mMobileL) {
							top: 13px; }
						img {
							display: block;
							width: 26px;
							height: 28px;
							transform: scale(0) translateZ(0);
							@include transition(transform); } }

					h2 {
						font-size: 32px;
						margin-bottom: 0;
						span {
							display: block; }
						@include max($mMobileL) {
							font-size: 24px; } }

					div.toggler {
						width: 30px;
						height: 30px;
						border-radius: 50%;
						@include transition(background, transform);
						display: flex;
						align-items: center;
						justify-content: center;
						flex-grow: 0;
						flex-shrink: 0;
						&.toggled {
							transform: rotateX(180deg) translateZ(0); }
						img {
							display: block;
							width: 16px;
							height: 16px; } } }

				div.accordion {
					@include transition(margin-top, opacity);
					transform: translateZ(0);
					will-change: height, margin-top, opacity;
					&.expanded {
						margin-top: 24px; }
					&:not(.expanded) {
						opacity: 0; }

					.content {
						font-weight: 200;
						@include max($mMobileL) {
							font-size: 14px; } }

					div.graphics {
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;
						padding-top: 24px;
						@include childSpaceRight(24px);

						img {
							display: block;
							width: calc(50% - 12px); } } } } } } }
