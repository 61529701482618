@import "styles/vars";

section.c-home-join-whitelist {
	div.hjws-content {
		max-width: $sizeMaxWidth;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 40px 120px;
		background: $colorAccent;
		border-radius: 105px;
		@include max($mTabletB) {
			border-radius: 32px;
			padding: 40px 80px;
			flex-direction: column; }
		@include max($mMobileL) {
			padding: 40px; }

		h2 {
			font-size: 48px;
			margin-bottom: 0;
			line-height: 130%;
			@include max($mTabletB) {
				text-align: center;
				margin-bottom: 24px; }
			@include max($mMobileL) {
				font-size: 30px; }
			small {
				display: inline-block;
				font-size: 40px;
				vertical-align: top;
				margin-top: -5px;
				@include max($mMobileL) {
					display: block;
					margin-top: 0; } } }

		.u-simple-button {
			width: 164px; } } }
