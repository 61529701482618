@font-face {
	font-family: "Kanit";
	font-weight: 100;
	src: url("/static/fonts/kanit-100.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 100;
	font-style: italic;
	src: url("/static/fonts/kanit-100i.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 200;
	src: url("/static/fonts/kanit-200.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 200;
	font-style: italic;
	src: url("/static/fonts/kanit-200i.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 300;
	src: url("/static/fonts/kanit-300.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 300;
	font-style: italic;
	src: url("/static/fonts/kanit-300i.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 400;
	src: url("/static/fonts/kanit-400.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 400;
	font-style: italic;
	src: url("/static/fonts/kanit-400i.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 500;
	src: url("/static/fonts/kanit-500.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 500;
	font-style: italic;
	src: url("/static/fonts/kanit-500i.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 600;
	src: url("/static/fonts/kanit-600.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 600;
	font-style: italic;
	src: url("/static/fonts/kanit-600i.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 700;
	src: url("/static/fonts/kanit-700.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 700;
	font-style: italic;
	src: url("/static/fonts/kanit-700i.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 800;
	src: url("/static/fonts/kanit-800.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 800;
	font-style: italic;
	src: url("/static/fonts/kanit-800i.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 900;
	src: url("/static/fonts/kanit-900.ttf") format("truetype");
	font-display: swap; }

@font-face {
	font-family: "Kanit";
	font-weight: 900;
	font-style: italic;
	src: url("/static/fonts/kanit-900i.ttf") format("truetype");
	font-display: swap; }



/* Condensed */

@font-face {
	font-family: "Fjalla One";
	font-weight: 400;
	src: url("/static/fonts/fjalla-400.ttf") format("truetype");
	font-display: swap; }



/* Emoji */

@font-face {
	font-family: "Emoji";
	font-weight: 400;
	src: url("/static/fonts/emoji.ttf") format("truetype");
	font-display: swap; }
