@import "styles/vars";

div.hts-map {
	@include pos(absolute, calc(50% - 253px), auto, 40px);
	width: 505px;
	height: 505px;
	border-radius: 50%;
	border: 1px solid rgba(white, .12);
	display: flex;
	align-items: center;
	justify-content: center;
	transform-origin: 0% 0%;
	@include max($mTabletA) {
		@include pos(relative, auto, auto, auto, auto);
		margin: 0 auto;
		margin-top: 48px; }

	&:before {
		content: "";
		width: 369px;
		height: 369px;
		border-radius: 50%;
		border: 1px solid rgba(white, .12); }

	&.has-highlight {
		.absolute-bubbles {
			.bubble {
				&:not(.highlighted) {
					box-shadow: none;
					filter: brightness(90%) contrast(50%); }
				&.highlighted {
					transform: scale(1.2) translateZ(0); } } }

		.orbit {
			opacity: 0; } }

	.bubble {
		border-radius: 50%;
		box-shadow: 0px 0px 20px rgba(0, 224, 255, 0.13), 0px 4px 80px rgba(0, 84, 248, 0.48), 0px 0px 20px rgba(black, 1), inset 0px 2px 10px rgba(white, 0.58);
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		@include transition;
		span.value {
			font-size: 20px;
			font-weight: 700; }
		span.caption {
			font-weight: 200; }

		&.x-small {
			background: linear-gradient(to right, $colorMain -2.78%, $colorAccent 79.19%);
			width: 28px;
			height: 28px; }
		&.smaller {
			background: linear-gradient(to right, $colorMain -16.76%, $colorAccent 97.82%);
			width: 74px;
			height: 74px;
			span.value {
				margin-top: -3px; }
			span.caption {
				font-size: 10px;
				margin-top: -4px; } }

		&.small {
			background: linear-gradient(to right, $colorMain -16.76%, $colorAccent 97.82%);
			width: 88px;
			height: 88px;
			span.value {
				margin-top: -3px; }
			span.caption {
				font-size: 14px;
				margin-top: -3px; } }

		&.medium {
			background: linear-gradient(to right, $colorMain 11.69%, $colorAccent 117.77%);
			width: 107px;
			height: 107px; }

		&.large {
			background: linear-gradient(to right, $colorMain 23.19%, $colorAccent 153.04%);
			width: 174px;
			height: 174px; } }

	.absolute-bubbles {
		@include pos(absolute, 0, 0, 0, 0);

		.bubble {
			cursor: pointer;
			&:nth-child(1) {
				top: 65px;
				left: 326px; }
			&:nth-child(2) {
				top: 333px;
				left: 99px; }
			&:nth-child(3) {
				top: 438px;
				left: 308px; }
			&:nth-child(4) {
				top: 248px;
				left: 372px; }
			&:nth-child(5) {
				top: 321px;
				left: -11px; }
			&:nth-child(6) {
				top: -14px;
				left: 87px; }
			&:nth-child(7) {
				top: 158px;
				left: 24px; }
			&:nth-child(8) {
				top: calc(50% - 87px);
				left: calc(50% - 87px); } } }

	.orbit {
		position: absolute;
		top: 50%;
		animation-name: rotate;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		pointer-events: none;
		@include transition(opacity);
		> .bubble {
			animation-name: rotate;
			animation-timing-function: linear;
			animation-iteration-count: infinite; }

		> img {
			display: block;
			animation-name: rotate;
			animation-timing-function: linear;
			animation-iteration-count: infinite; }

		&.wide {
			left: 0;
			right: 0;
			animation-duration: 24000ms;
			> .bubble {
				animation-duration: 24000ms;
				animation-direction: reverse; } }

		&.short {
			left: 108px;
			right: 108px;
			animation-duration: 12000ms;
			animation-direction: reverse;
			> .bubble {
				animation-duration: 12000ms; } }

		&.asteroid-wide {
			left: -24px;
			right: -24px;
			animation-duration: 32000ms;
			> img {
				animation-duration: 32000ms;
				animation-direction: reverse; } }

		&.asteroid-semiwide {
			left: 12px;
			right: 12px;
			animation-duration: 42000ms;
			animation-direction: reverse;
			> img {
				animation-duration: 42000ms; } }

		&.asteroid-medium {
			left: 48px;
			right: 48px;
			animation-duration: 20000ms;
			animation-direction: reverse;
			> img {
				animation-duration: 20000ms; } }

		&.asteroid-small {
			left: 120px;
			right: 120px;
			animation-duration: 18000ms;
			> img {
				animation-duration: 18000ms;
				animation-direction: reverse; } }

		.bubble {
			top: -14px;
			right: -14px; } } }

section.c-home-tokenomics {
	padding-top: 160px;
	padding-bottom: 160px;
	overflow: hidden;
	@include max($mMobileL) {
		padding-bottom: 100px; }
	div.hts-content {
		max-width: $sizeMaxWidth;
		margin: 0 auto;
		position: relative;
		h2 {
			@include max($mTabletA) {
				text-align: center; }
			@include max($mMobileL) {
				font-size: 30px; } }
		p {
			font-weight: 200;
			max-width: 520px;
			strong {
				color: $colorMain;
				font-weight: 600; } }

		ul.hts-list {
			list-style: none;
			width: 410px;
			margin-top: 32px;
			@include max($mTabletA) {
				margin-left: auto;
				margin-right: auto; }
			@include max($mMobileL) {
				width: 100%; }

			li {
				padding: 0 16px;
				height: 37px;
				border-radius: 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 14px;
				font-weight: 600;
				margin-bottom: 4px;
				position: relative;
				overflow: hidden;
				@include transition(filter);

				span.value {
					color: $colorMain;
					@include transition(transform); }

				&.primary {
					background: $colorHighlight; }
				&:not(.primary) {
					background: $gradientBackground;
					cursor: pointer;
					> * {
						position: relative; }

					&:before {
						content: "";
						@include pos(absolute, 0, 0, 0, 0);
						background: $gradientMain;
						opacity: 0;
						@include transition(opacity); }

					&.highlighted {
						&:before {
							opacity: 1; }
						span.value {
							transform: translateX(-10px); } }

					&.dimmed {
						filter: brightness(50%) contrast(50%); } } } } } }
