@import "styles/vars";

footer.c-footer {
	padding: 48px 32px;
	background: #0F1114;
	div.fo-inner-wrapper {
		max-width: $sizeMaxWidth;
		margin: 0 auto;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@include max($mTabletB) {
			display: block; }

		div.left {
			@include max($mTabletB) {
				text-align: center; }
			img {
				display: block;
				margin-bottom: 12px;
				@include max($mTabletB) {
					margin-left: auto;
					margin-right: auto; } }

			span.copyright {
				font-size: 12px;
				font-weight: 200; } }

		div.right {
			padding-top: 27px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
			@include childSpaceRight(74px);
			@include max($mTabletB) {
				justify-content: center; }
			@include max($mMobileL) {
				max-width: 360px;
				margin: 0 auto;
				@include childSpaceRight(12px);
				justify-content: space-between; }

			div.column {
				@include max($mTabletB) {
					text-align: center; }
				@include max($mMobileL) {
					font-size: 14px; }
				div.header {
					font-weight: 600;
					margin-bottom: 16px; }

				div.links {
					@include childSpaceBottom(16px);

					.link {
						font-weight: 200; } } } } } }
