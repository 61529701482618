@import "styles/vars";

section.c-home-roadmap-temp {
	padding-top: 200px;
	padding-bottom: 200px;
	position: relative;
	@include max($mTabletB) {
		padding-top: 120px;
		padding-bottom: 120px; }
	@include max($mMobileL) {
		padding-left: 32px !important; }

	span.hrts-progress {
		@include pos(absolute, 182px + 200px, 0, 0);
		width: 100%;
		height: 2px;
		background: rgba(white, .06);
		@include max($mTabletB) {
			height: auto;
			width: 2px;
			top: 120px + 33px;
			left: 31px;
			right: auto;
			bottom: 200px; }
		@include max($mMobileL) {
			top: 120px + 21px; }

		> * {
			@include pos(absolute, 0, 0, 0);
			height: 2px;
			@include max($mTabletB) {
				height: auto;
				width: 2px !important; } }

		span.current {
			background: $colorPositive;
			width: 4vw;
			@include max(1400px) {
				width: 20px; }
			@include max($mTabletB) {
				height: 50px; } }


		span.potential {
			@include transition(width);
			background: $colorAccent;
			opacity: .5;
			@include max($mTabletB) {
				display: none; } } }

	div.hrts-content {
		max-width: $sizeMaxWidth;
		margin: 0 auto;
		@include max($mTabletB) {
			padding-left: 32px;
			position: relative; }

		img.star {
			display: none;
			width: 26px;
			height: 28px;
			@include pos(absolute, 7px, -13px);

			@include max($mTabletB) {
				display: block; } }

		h2 {
			margin-bottom: 56px;
			@include max($mMobileL) {
				font-size: 36px;
				margin-bottom: 38px; } }

		div.hrts-items {
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
			@include max($mTabletB) {
				display: block; }

			div.hrts-item {
				// width: 20%
				padding-right: 32px;
				@include max($mTabletB) {
					position: relative;
					margin-bottom: 24px; }
				&:last-child {
					flex-grow: 0;
					flex-shrink: 0; }
				&:hover {
					h3 {
						color: $colorMain; }
					img {
						transform: scale(1.5) rotate(180deg); }
					ul {
						li {
							opacity: 1;
							&:before {
								color: $colorAccent; }
							span {
								color: $colorMain; } } } }
				h3 {
					font-size: 32px;
					line-height: 120%;
					@include transition(color);
					@include max($mTabletB) {
						margin-bottom: 15px; } }
				img {
					display: block;
					margin-top: 15px;
					margin-bottom: 11px;
					margin-left: -6px;
					@include transition(transform);
					@include max($mTabletB) {
						margin: 0 !important;
						@include pos(absolute, 13px, -38px); } }
				ul {
					margin-left: -10px;
					list-style: none;
					@include childSpaceBottom(12px);

					li {
						font-size: 14px;
						opacity: .7;
						@include transition(opacity);
						position: relative;
						padding-left: 21px;
						&:before {
							content: "\2022";
							color: white;
							font-size: 16px;
							width: 21px;
							height: 21px;
							display: flex;
							align-items: center;
							justify-content: center;
							@include pos(absolute, 0px, 0px);
							@include transition(color); }
						span {
							@include transition(color); } } } } } } }
