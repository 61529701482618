@import "styles/vars";

section.c-home-description {
	padding-top: 120px;
	padding-bottom: 120px;
	@include max($mTabletB) {
		padding-bottom: 0; }

	div.hds-content {
		width: 100%;
		max-width: $sizeMaxWidth;
		margin: 0 auto;
		padding: 88px;
		background: $colorAccent;
		border-radius: 40px;
		font-size: 72px;
		font-family: $fontHead;
		line-height: 130%;
		text-align: center;
		position: relative;
		@include max($mDesktopS) {
			font-size: 5.54vw; }
		@include max($mMobileL) {
			font-size: 30px;
			padding: 45px 18px 30px 18px; }

		div.hds-labels {
			@include pos(absolute, 0, 0, 0, 0);
			pointer-events: none;
			@include max($mMobileL) {
				@include pos(relative, auto, -18px, -18px, auto);
				width: calc(100% + 36px);
				height: 180px; }

			> * {
				position: absolute;
				&:nth-child(1) {
					top: 50px;
					right: 20px;
					transform: rotate(39deg);
					@include max($mMobileL) {
						top: 48px;
						right: 24%;
						transform: rotate(24deg); }
					@include max($mMobileS) {
						transform: rotate(24deg) scale(.8); } }
				&:nth-child(2) {
					right: 24px;
					bottom: 114px;
					@include max($mMobileL) {
						top: 30px;
						right: 3%;
						bottom: auto; }
					@include max($mMobileS) {
						transform: scale(.8); } }
				&:nth-child(3) {
					right: 48px;
					bottom: 32px;
					transform: rotate(-17deg);
					@include max($mMobileL) {
						right: 7%; }
					@include max($mMobileS) {
						transform: rotate(-17deg) scale(.8); } }
				&:nth-child(4) {
					right: 168px;
					bottom: 30px;
					transform: rotate(21deg);
					@include max($mMobileL) {
						top: 96px;
						left: 32%;
						right: auto;
						bottom: auto; }
					@include max($mMobileS) {
						transform: rotate(21deg) scale(.8); } }
				&:nth-child(5) {
					left: 138px;
					bottom: 42px;
					transform: rotate(-24deg);
					@include max($mMobileL) {
						top: 42px;
						left: 11%;
						bottom: auto; }
					@include max($mMobileS) {
						transform: rotate(-24deg) scale(.8); } }
				&:nth-child(6) {
					left: 30px;
					bottom: 60px;
					transform: rotate(8deg);
					@include max($mMobileL) {
						left: 6%;
						bottom: 28px; }
					@include max($mMobileS) {
						transform: rotate(8deg) scale(.8); } } } } } }
