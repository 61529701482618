@import "styles/vars";

div.c-pl-bottom-item {
	position: absolute;
	border-radius: 50%;
	overflow: visible;
	@include max($mMobileL) {
		display: none; }

	&:nth-child(1) {
		opacity: .38;
		width: 114px;
		height: 70px;
		background: $colorMain;
		top: 140px;
		right: -57px;
		filter: blur(57px); }

	&:nth-child(2) {
		opacity: .2;
		width: 416px;
		height: 302px;
		background: #6a52ff;
		top: 631px;
		left: -120px;
		filter: blur(77px); }

	&:nth-child(3) {
		opacity: .2;
		width: 550px;
		height: 400px;
		background: #6a52ff;
		top: 1515px;
		right: -300px;
		filter: blur(77px); }

	&:nth-child(4) {
		opacity: .12;
		width: 550px;
		height: 400px;
		background: #6a52ff;
		top: 2200px;
		left: -200px;
		filter: blur(77px); }

	&:nth-child(5) {
		opacity: .4;
		width: 540px;
		height: 342px;
		background: #133d95;
		top: 2515px;
		right: -300px;
		filter: blur(107px); } }
