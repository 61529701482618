@import "styles/vars";

.u-fade-in {
	@include singleRunAnimation(fadeIn, 450ms);
	animation-fill-mode: backwards; }

.u-clip-text {
	background-clip: text;
	-moz-background-clip: text;
	-webkit-background-clip: text;
	color: transparent; }

.u-gradient-text {
	@extend .u-clip-text;
	background-image: $gradientMain;
	&.hoverable {
		@include transition(color);
		*:not(.disabled):hover > & {
			color: white; } } }

.u-simple-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 32px;
	height: 48px;
	border-radius: 24px;
	position: relative;
	font-weight: 700;
	cursor: pointer;
	background: white;
	box-shadow: inset 0 0 0 2px white;
	@include transition(background, filter);
	&.disabled {
		filter: brightness(50%) contrast(50%);
		cursor: not-allowed; }
	&:not(.disabled):hover {
		background: transparent; } }

.u-gradient-button {
	@extend .u-simple-button;
	background: transparent;
	> * {
		z-index: 1; }
	&:hover {
		&:after {
			opacity: 0; } }

	&:before {
		content: "";
		@include pos(absolute, 0, 0, 0, 0);
		border-radius: 24px;
		background: $gradientMain; }

	&:after {
		content: "";
		@include pos(absolute, 2px, 2px, 2px, 2px);
		background: $colorDark;
		border-radius: 22px;
		@include transition(opacity); } }

.u-link {
	position: relative;
	cursor: pointer;
	@include transition(color);
	&.disabled {
		cursor: not-allowed;
		opacity: .38;
		filter: blur(1px); }

	&:before {
		content: "";
		@include pos(absolute, -2px, -8px);
		width: 8px;
		height: 8px;
		border-top: 2px dotted $colorMain;
		border-left: 2px dotted $colorMain;
		pointer-events: none;
		@include transition(opacity, transform);
		transform: translate(-10px, -10px) translateZ(0);
		opacity: 0; }

	&:after {
		content: "";
		@include pos(absolute, auto, auto, -8px, -2px);
		width: 8px;
		height: 8px;
		border-right: 2px dotted $colorMain;
		border-bottom: 2px dotted $colorMain;
		pointer-events: none;
		@include transition(opacity, transform);
		transform: translate(10px, 10px) translateZ(0);
		opacity: 0; }

	&:not(.disabled):hover {
		color: $colorMain;
		&:before, &:after {
			opacity: 1;
			transform: none; } } }
