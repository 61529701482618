@import "styles/vars";

div.c-simple-label {
	padding: 8px 16px;
	border-radius: 24px;
	background: white;
	font-size: 14px;
	line-height: 21px;
	color: black;
	font-family: $fontMain;
	text-align: center; }
