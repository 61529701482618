@import "styles/vars";

section.c-home-main {
	height: 100vh;
	position: relative;
	@include max($mTabletA) {
		height: 100svh; }

	div.hms-inner-wrapper {
		max-width: $sizeMaxWidth;
		margin: 0 auto;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		@include max($mTabletB) {
			align-items: center; } }

	h1 {
		width: 100%;
		font-size: 96px;
		line-height: 120%;
		position: relative;
		@include max($mDesktopS) {
			font-size: 7vw; }
		@include max($mTabletB) {
			position: static; }
		@include max($mMobileL) {
			font-size: 9vw; }
		// +max($mTabletA)
		// 	font-size: 6vw

		img.hms-big-drops {
			@include pos(absolute, auto, auto, 2%, 2%);
			display: block;
			animation-name: floatY;
			animation-timing-function: ease;
			animation-direction: alternate;
			animation-iteration-count: infinite;
			animation-duration: 6789ms;
			width: 202px;
			height: 202px;
			@include max($mTabletA) {
				bottom: -24%; }
			@include max($mTabletB) {
				display: none; } } }

	p {
		text-align: left;
		max-width: 610px;
		font-size: 19px;
		font-weight: 200;
		margin-top: 20px;
		@include max($mTabletB) {
			text-align: center;
			max-width: 480px; }
		@include max($mMobileL) {
			font-size: 14px;
			line-height: 160%;
			max-width: 320px; } }

	div.hms-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 32px;
		@include max($mDesktopS) {
			height: 1.2em; }
		@include max($mTabletB) {
			justify-content: center; }
		@include max($mMobileL) {
			margin-top: 12px; }
		&:first-child {
			margin-top: 0; } }

	div.hms-fx {
		flex-grow: 0;
		flex-shrink: 0;
		background: $gradientBackground;
		height: 116px;
		width: 390px;
		border-radius: 58px;
		-webkit-backdrop-filter: blur(24px);
		backdrop-filter: blur(24px);
		position: relative;
		@include max($mDesktopS) {
			position: absolute;
			right: 0; }
		@include max($mTabletA) {
			transform-origin: 100% 50%;
			transform: scale(0.769230769) translateZ(0); }
		@include max($mTabletB) {
			transform: none;
			position: static;
			background: transparent;
			-webkit-backdrop-filter: none;
			backdrop-filter: none;
			width: 0px;
			height: 0px; }

		img {
			position: absolute;
			display: block;
			animation-name: floatY;
			animation-timing-function: ease;
			animation-direction: alternate;
			animation-iteration-count: infinite; }

		img:not(.dust) {
			@include max($mTabletB) {
				z-index: -1; }

			&:nth-child(1) {
				top: 39px;
				left: 15px;
				animation-delay: -60ms;
				animation-duration: 3000ms;
				width: 50px;
				height: 50px;
				@include max($mTabletB) {
					display: none; } }

			&:nth-child(2) {
				top: -15px;
				left: 128px;
				animation-delay: -800ms;
				animation-duration: 3600ms;
				width: 93px;
				height: 93px;
				@include max($mTabletB) {
					top: auto;
					left: 16px;
					bottom: 240px;
					width: 42px;
					height: 42px; } }

			&:nth-child(3) {
				top: 73px;
				left: 227px;
				animation-delay: -370ms;
				animation-duration: 2400ms;
				width: 66px;
				height: 66px;
				@include max($mTabletB) {
					top: auto;
					left: auto;
					right: 20%;
					bottom: 100px; } }

			&:nth-child(4) {
				top: -50px;
				left: 270px;
				animation-delay: -600ms;
				animation-duration: 4800ms;
				width: 98px;
				height: 98px;
				@include max($mTabletB) {
					top: 84px;
					left: auto;
					right: 28px; } } }

		img.dust {
			@include max($mTabletB) {
				display: none; } } }

	div.hms-next {
		width: 60px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include pos(absolute, auto, calc(50% - 30px), auto, 30px);
		font-size: 48px;

		img {
			width: 140px;
			height: 142px; } } }

