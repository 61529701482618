@import "styles/vars";

section.c-home-motivation {
	padding-top: 160px;

	div.hmos-content {
		width: 100%;
		max-width: $sizeMaxWidth;
		margin: 0 auto;
		background: $gradientReversed;
		height: 420px;
		border-radius: 40px;
		padding: 56px 64px;
		position: relative;
		@include max($mDesktopS) {
			height: auto; }
		@include max($mTabletB) {
			padding-bottom: 0; }
		@include max($mMobileL) {
			padding: 40px 12px 0 12px; }

		h2 {
			line-height: 130%;
			@include max($mTabletB) {
				text-align: center; }
			@include max($mMobileL) {
				font-size: 30px; } }

		> p {
			font-size: 19px;
			@include max($mTabletB) {
				text-align: center; }
			@include max($mMobileL) {
				font-size: 14px; } }

		div.arrow {
			display: none;
			font-size: 16px;
			line-height: 24px;
			margin-top: 12px;
			transform: scaleY(1.5);
			text-align: center;
			@include max($mTabletB) {
				display: block; } }

		div.hmos-labels {
			@include pos(absolute, auto, 64px, auto, 56px);
			@include childSpaceBottom(10px);
			@include max($mDesktopS) {
				position: static;
				margin-top: 24px; }

			div.hmos-label-row {
				@include childSpaceRight(10px);
				display: flex;
				align-items: flex-end;
				justify-content: flex-start;
				@include max($mDesktopS) {
					flex-direction: column;
					align-items: flex-start;
					transform: none !important;
					@include childSpaceRight(0);
					@include childSpaceBottom(10px);
					> * {
						transform: none !important; } }
				@include max($mTabletB) {
					align-items: center; }

				&:nth-child(1) {
					> * {
						&:nth-child(1) {
							margin-bottom: 10px;
							margin-right: 5px;
							@include max($mDesktopS) {
								margin-right: 0; } } } }

				&:nth-child(2) {
					transform: translateX(20px);
					@include max($mDesktopS) {
						flex-direction: column-reverse;
						@include childSpaceBottom(0);
						@include childSpaceTop(10px); } }

				> * {
					&:nth-child(1) {
						transform: rotate(-6deg); }
					&:nth-child(2) {
						transform: rotate(3deg); } } } }

		div.hmos-graphics {
			@include pos(absolute, auto, auto, 60px, 0);
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			pointer-events: none;
			@include max($mTabletB) {
				position: static;
				margin-top: 24px;
				justify-content: center;
				width: 70%;
				margin-left: auto;
				margin-right: auto; }
			@include max($mMobileL) {
				width: calc(100% - 36px); }

			img {
				display: block;
				width: 226px;
				@include max($mDesktopS) {
					width: 17vw; }
				@include max($mTabletB) {
					width: calc(55% + 50px); }

				&:last-child {
					margin-left: 16px;
					@include max($mTabletB) {
						margin-left: -50px;
						width: 45%; } } } } } }

