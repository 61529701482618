@import "styles/vars";

section.c-home-overview {
	height: 420vh;

	div.hos-inner-wrapper {
		position: sticky;
		top: 0;
		margin: 0 auto;
		max-width: $sizeMaxWidth;
		// padding-top: 30vh
		// padding-bottom: 320px
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		div.hos-content-wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start; }

		div.hos-scene {
			@include pos(absolute, calc(50% - 340px), auto, 0);
			width: 333px;
			height: 680px;
			transform-origin: 100% 50%;
			@include max($mTabletA + 200px) {
				transform: scale(.9); }
			@include max($mTabletA + 100px) {
				transform: scale(.8); }
			@include max($mTabletA) {
				transform: scale(.7); }
			@include max($mTabletA - 100px) {
				transform: scale(.6); }
			@include max($mTabletA - 200px) {
				transform: scale(.5); }


			img.mockup {
				width: 100%;
				height: 100%;
				@include pos(absolute, 0, 0, 0, 0);
				display: block; }

			img.cursor {
				filter: drop-shadow(2px 4px 6px black);
				transform: rotate(-42deg) translate(-4px, -6px); }

			div.swipe {
				background-color: $colorHighlight;
				height: 40px;
				border-radius: 20px; }

			div.ripple {
				width: 40px;
				height: 40px;
				border-radius: 50%; } }

		div.hos-current {
			margin-left: 40px;
			width: 420px;
			animation-duration: 1200ms;
			@include max($mTabletA) {
				width: 36vw; }
			h2 {
				@include max($mTabletA) {
					font-size: 5.09vw; }
				span {
					display: block; } }
			p {
				font-weight: 200;
				margin-bottom: 40px; }

			span.hos-next {
				font-size: 16px;
				@include transition;
				.__nosmooth & {
					pointer-events: none;
					opacity: 0; } } }

		div.hos-steps-wrapper {
			width: 260px;
			position: relative;

			div.hos-progress {
				background: rgba(white, .12);
				width: 2px;
				@include pos(absolute, 0.75em, auto, auto, 0.75em);
				> span {
					display: block;
					background: $colorPositive; } }

			ul.hos-steps {
				list-style: none;
				margin: 0;
				padding: 0;

				li.hos-step {
					position: relative;
					margin-bottom: 64px;
					padding-left: 20px;
					&:last-child {
						margin-bottom: 0; }
					&.visited {
						span.hos-point {
							img {
								transform: scale(1) translateZ(0); } }

						p {
							color: $colorPositive;
							font-weight: 600; } }

					span.hos-point {
						width: 4px;
						height: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
						background: #D9D9D9;
						border-radius: 50%;
						@include pos(absolute, calc(0.75em - 2px), -1px);

						img {
							transform: scale(0) translateZ(0);
							@include transition(transform); } }

					div.hos-current-content {
						p {
							color: rgba(white, .12);
							font-weight: 300;
							line-height: 1.5em;
							@include transition(color); } } } } } } }
