@import "styles/vars";

html {
	&:not(.__nosmooth) {
		scroll-behavior: smooth; } }

html, body {
	position: relative;
	height: 100%;
	overscroll-behavior-y: contain; }

body {
	margin: 0;
	padding: 0;
	font-family: $fontMain;
	font-size: 16px;
	overflow-y: scroll;
	color: white;
	background: $colorDark;
	@include scrollbar($colorAccent, 0);
	* {
		color: inherit;
		font-size: inherit;
		font-family: inherit;
		margin: 0;
		padding: 0;
		appearance: none;
		background: transparent;
		border-radius: 0;
		box-sizing: border-box;
		border: none;
		outline: none;
		text-decoration: none;
		&:invalid {
			box-shadow: none; }
		&:before, &:after {
			box-sizing: border-box; } } }

img {
	object-fit: cover;
	object-position: center; }

h1, h2, h3, h4, h5, h6 {
	font-family: $fontHead;
	font-weight: 400; }

.sol__tooltip.fade-out {
	@include singleRunAnimation(fadeOut, 250ms, $transition); }
