@import "styles/vars";

@keyframes whitelistFadeIn {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes whitelistFadeOut {
	0% {
		opacity: 1; }
	100% {
		opacity: 0; } }

div.c-whitelist-modal {
	@include pos(fixed, 0, 0, 0, 0);
	z-index: 2400;
	background: rgba(black, .54);
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-backdrop-filter: blur(47px);
	backdrop-filter: blur(47px);
	padding: 32px;
	@include max($mMobileL) {
		padding: 0 16px; }
	&.fade-in {
		@include singleRunAnimation(whitelistFadeIn, 480ms);
		div.wlm-content {
			@include singleRunAnimation(shiftInY, 480ms); } }
	&.fade-out {
		@include singleRunAnimation(whitelistFadeOut, 480ms);
		div.wlm-content {
			@include singleRunAnimation(shiftOutY, 480ms); } }

	div.wlm-content {
		max-width: 855px;
		padding: 64px 128px;
		border-radius: 40px;
		position: relative;
		background: $colorAccent;
		@include max($mTabletB) {
			padding: 64px; }
		@include max($mMobileL) {
			padding: 40px 16px;
			border-radius: 32px; }

		span.close {
			width: 48px;
			height: 48px;
			@include pos(absolute, -36px, auto, -36px);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 42px;
			cursor: pointer;
			@include max($mMobileL) {
				top: 0;
				right: 0; } }

		h1 {
			font-size: 48px;
			line-height: 130%;
			text-align: center;
			@include max($mMobileL) {
				font-size: 30px; } }

		p {
			font-size: 19px;
			line-height: 160%;
			text-align: center;
			margin: 16px 0 32px 0;
			@include max($mMobileL) {
				font-size: 14px; } }

		div.actions-wrapper {
			position: relative;
			overflow: hidden;
			&.processing {
				div.status {
					transform: none; }
				div.form {
					transform: translateY(100%) translateZ(0); } }
			> * {
				@include transition(transform); }

			div.status {
				@include pos(absolute, 0, 0, 0, 0);
				display: flex;
				align-items: center;
				justify-content: center;
				transform: translateY(-100%) translateZ(0);
				font-size: 20px; }

			div.form {
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include max($mMobileL) {
					display: block; }

				input {
					height: 48px;
					color: white;
					padding: 0 24px;
					border-radius: 24px;
					border: 2px solid white;
					width: 100%;
					flex-grow: 1;
					flex-shrink: 1;
					font-weight: 600;
					@include placeholder {
						color: rgba(white, .54); } }

				.u-simple-button {
					width: 164px;
					margin-left: 16px;
					flex-grow: 0;
					flex-shrink: 0;
					@include max($mMobileL) {
						margin-left: 0;
						margin-top: 16px;
						width: 100%; } } } } } }
