@import "styles/vars";

section.c-coming-soon {
	div.hcss-content {
		max-width: $sizeMaxWidth;
		margin: 0 auto;
		padding: 64px;
		position: relative;
		height: 380px;
		border-radius: 40px;
		background: $gradientReversed;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@include max($mTabletB) {
			display: block;
			height: auto;
			padding: 40px;
			padding-bottom: 0; }
		@include max($mMobileL) {
			padding: 40px 12px 0 12px; }

		h2 {
			margin-bottom: 0;
			font-size: 72px;
			@include max($mTabletA) {
				width: 40%; }

			@include max($mTabletB) {
				width: 100%;
				text-align: center;
				font-size: 48px; }

			@include max($mMobileL) {
				font-size: 30px; } }

		div.hcss-graphics {
			@include pos(absolute, auto, auto, 60px, 0);
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			pointer-events: none;
			@include max($mTabletB) {
				position: static;
				margin-top: 24px;
				justify-content: center;
				width: 70%;
				margin-left: auto;
				margin-right: auto; }
			@include max($mMobileL) {
				width: calc(100% - 36px); }

			img {
				display: block;
				width: 226px;
				@include max($mDesktopS) {
					width: 17vw; }
				@include max($mTabletB) {
					width: calc(55% + 50px); }

				&:last-child {
					margin-left: 16px;
					@include max($mTabletB) {
						margin-left: -50px;
						width: 45%; } } } } } }
