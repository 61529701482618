@import "styles/vars";

div.c-creator-card {
	padding: 24px 47px 40px 47px;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	@include transition;
	@include max($mMobileL) {
		padding: 24px 0;
		height: 280px;
		justify-content: flex-start;
		will-change: height;
		&.preserve-space {
			&.force-info {
				height: 420px; } } }

	&:before {
		content: "";
		@include pos(absolute, 0, 0, 0, 0);
		-webkit-backdrop-filter: blur(12px);
		backdrop-filter: blur(12px);
		background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(166, 165, 255, 0.2) 0%, rgba(110, 148, 244, 0.0447917) 77.08%, rgba(70, 76, 213, 0) 100%);
		@include max($mTabletB) {
			-webkit-backdrop-filter: none;
			backdrop-filter: none; } }

	&:hover, &.force-info {
		div.info {
			top: 48px;
			@include max($mMobileL) {
				top: 164px; } } }
	> * {
		position: relative; }

	> img {
		@include max($mMobileL) {
			width: 140px; } }

	span.name {
		margin-top: 24px;
		margin-bottom: 5px;
		font-family: $fontHead;
		font-size: 24px;
		line-height: 130%; }

	span.label {
		font-size: 14px;
		opacity: .6; }

	div.info {
		@include pos(absolute, 100%, 0, 0, 0);
		z-index: 12;
		padding: 36px 24px 24px 24px;
		pointer-events: none;
		@include transition;
		border-radius: 16px;
		overflow: hidden;
		will-change: top;
		@include max($mMobileM) {
			padding: 36px 12px; }

		&:before {
			content: "";
			border-radius: 16px;
			@include pos(absolute, 0, 0, 0, 0);
			-webkit-backdrop-filter: blur(40px);
			backdrop-filter: blur(40px);
			background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(166, 165, 255, 0.2) 0%, rgba(110, 148, 244, 0.0447917) 77.08%, rgba(70, 76, 213, 0) 100%); }

		&:after {
			content: "";
			@include pos(absolute, 8px, calc(50% - 16px));
			width: 32px;
			height: 4px;
			border-radius: 2px;
			background: white; }

		> * {
			position: relative;
			text-align: center; }

		div.header {
			font-size: 24px;
			font-family: $fontHead;
			line-height: 130%;
			margin-bottom: 4px; }

		div.label {
			opacity: .54;
			font-size: 14px;
			margin-bottom: 24px; }

		p {
			font-size: 14px; } } }
