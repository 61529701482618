@import "styles/vars";

img.c-pl-dust-item {
	position: absolute;
	display: block;
	animation-name: floatY;
	animation-direction: alternate;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
	&.op {
		opacity: .5; }

	@include max($mMobileL) {
		animation-name: none; } }
