@import "styles/vars";

img.c-pl-big-drops-item {
	position: absolute;
	display: block;
	animation-name: floatY;
	animation-direction: alternate;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
	@include max($mMobileL) {
		animation-name: none; } }
